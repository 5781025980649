import { Fade, Typography } from "@mui/material";
import ProviderLoading from "providers/ProviderLoading/ProviderLoading";
import { ReactNode, createContext, useContext, useMemo } from "react";
import { EnvironmentApi } from "generated/gateway/apis";
import { Configuration, ResponseError } from "generated/gateway/runtime";
import useSWR from "swr";
import { EnvironmentModel } from "generated/gateway/models";

const EnvironmentContext = createContext<{
  features: string[];
  application: string;
  production: boolean | undefined;
}>({
  features: [],
  application: "",
  production: true,
});

export const useEnvironment = () => {
  const context = useContext(EnvironmentContext);

  return {
    features: context.features,
    application: context.application,
    isFeatureEnabled: (feature: string) => {
      return context.features.includes(feature);
    },
    isProduction: context.production,
  };
};

export interface EnvironmentProviderProps {
  children: ReactNode;
  application: string;
}

const useGetEnvironment = () => {
  const environmentApi: EnvironmentApi = new EnvironmentApi(
    new Configuration({
      basePath: "/api",
      credentials: "include",
    })
  );

  return useSWR<EnvironmentModel, ResponseError>("/environment", () =>
    environmentApi.getEnvironment()
  );
};

const EnvironmentProvider = ({
  children,
  application,
}: EnvironmentProviderProps) => {
  const environment = useGetEnvironment();

  const globalContextValue = useMemo(
    () => ({
      application,
      features: environment.data?.applications?.[application]?.features || [],
      production: environment.data?.production,
    }),
    [application, environment.data?.applications]
  );

  const isLoading = !environment.data && !environment.error;
  if (isLoading) {
    return (
      <Fade in style={{ transitionDelay: "500ms" }}>
        <div>
          <ProviderLoading />
        </div>
      </Fade>
    );
  }

  if (environment.error || !environment.data) {
    return <Typography>Error</Typography>;
  }

  return (
    <EnvironmentContext.Provider value={globalContextValue}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentProvider;
