import { useSession } from "@ldms/mui-sdk/bootstrap";
import { AppNavigationList } from "@ldms/mui-sdk/components";
import { Loader } from "@ldms/mui-sdk/templates";
import { Box } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";

export default function NavigationList(): ReactElement {
  const { ready, t } = useTranslation(["tasks"]);
  const session = useSession();
  const hasPermissions = session.data.permissions.size > 0;
  const location = useLocation();

  const navigationOptions = [
    {
      to: "tasks",
      label: t("navigation.tasks_link"),
    },
  ];

  if (!hasPermissions) {
    return <></>;
  }

  return (
    <Box display="flex" justifyContent="flex-start">
      <Loader
        fallback=""
        ready={ready}
        render={() => (
          <AppNavigationList
            pathname={location.pathname}
            label={t("navigation_label")}
          >
            {navigationOptions.map((item) => (
              <AppNavigationList.ItemButton
                key={item.label}
                component={RouterLink}
                to={`/${item.to}`}
              >
                {item.label}
              </AppNavigationList.ItemButton>
            ))}
          </AppNavigationList>
        )}
      />
    </Box>
  );
}
