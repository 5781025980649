import { QueryKey } from "api/types";

export const all = (): QueryKey => ["tasks"];

export const list = (params?: {
  assigneeId?: string;
  from?: Date;
  to?: Date;
  customerId?: number;
  agreementId?: number;
}): QueryKey => [
  ...all(),
  "list",
  ...(params
    ? [
        params.agreementId,
        params.assigneeId,
        params.customerId,
        params.from,
        params.to,
      ]
    : []),
];

export const details = (): QueryKey => [...all(), "detail"];

export const detail = (taskId: number): QueryKey => [...details(), taskId];
