import { Box, Divider, Paper, TablePagination } from "@mui/material";
import { ChangeEventHandler, MouseEvent, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface ListLayoutProps {
  actions?: ReactNode;
  children: ReactNode;
  filters?: ReactNode;
  pagination?: {
    onPageChange: (
      event: MouseEvent<HTMLButtonElement> | null,
      page: number
    ) => void;
    onRowsPerPageChange: ChangeEventHandler<HTMLInputElement>;
    rowsPerPage: number;
    page: number;
    count: number | undefined;
  };
}

export default function ListLayout({
  actions,
  children,
  filters,
  pagination,
}: ListLayoutProps) {
  const { t } = useTranslation();
  const displayHeader = actions || filters;

  return (
    <Paper sx={{ display: "flex", flexDirection: "column", maxHeight: "100%" }}>
      <Box
        display="grid"
        gridTemplateRows="min-content 1fr min-content"
        height="100%"
        overflow="hidden"
      >
        {displayHeader && (
          <Box
            component="header"
            paddingX={2}
            paddingTop={filters ? 2 : 1.5}
            paddingBottom={1.5}
          >
            <Box display="flex" justifyContent="space-between">
              <Box>{filters} </Box>
              <Box>{actions}</Box>
            </Box>
          </Box>
        )}
        <Box sx={{ overflowY: "auto" }}>{children}</Box>
        {!!pagination?.count && (
          <Box>
            <Divider />
            <TablePagination
              component="div"
              backIconButtonProps={{
                "aria-label": t("pagination.back_button"),
              }}
              nextIconButtonProps={{
                "aria-label": t("pagination.next_button"),
              }}
              {...pagination}
              count={pagination.count}
              labelRowsPerPage={t("pagination.rows_per_page_label")}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
}
