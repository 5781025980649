import { Box, Container, Typography } from "@mui/material";
import { TasksContainer } from "modules/tasks/containers/TasksContainer";
import { useTranslation } from "react-i18next";

const TasksView = () => {
  const { t } = useTranslation(["tasks"]);

  return (
    <Box>
      <Box component="header" boxShadow={1} bgcolor="#ffffff">
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          minHeight={52}
          paddingX={3}
          paddingY={1.25}
        >
          <Box>
            <Typography component="h1" variant="h4">
              {t("page_header")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box py={3}>
        <Container maxWidth={false}>
          <TasksContainer />
        </Container>
      </Box>
    </Box>
  );
};

export { TasksView };
