import { TasksApi } from "generated/tasks/apis";
import { useApi } from "providers/ApiProvider/ApiProvider";
import { useQuery, UseQueryReturn } from "@ldms/mui-sdk/cqrs";
import * as keys from "../keys";
import { TaskModel } from "generated/tasks/models";

export const useGetTask = (taskId: number): UseQueryReturn<TaskModel> => {
  const api = useApi(TasksApi);

  return useQuery(keys.detail(taskId), () => api.getTask({ taskId: taskId }));
};
