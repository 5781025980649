import { UseCommandOptions, useCommand } from "@ldms/mui-sdk/cqrs";
import { ResponseError } from "generated";
import { TasksApi } from "generated/tasks/apis";
import { UpdateStatusModel } from "generated/tasks/models";
import { useApi } from "providers/ApiProvider/ApiProvider";
import * as keys from "../keys";
import usePartialMutate from "sdk/hooks/usePartialMutate";

const useUpdateTaskStatus = (
  taskId: number,
  options: UseCommandOptions<void, ResponseError> = {}
) => {
  const api = useApi(TasksApi);
  const partialMutate = usePartialMutate();

  return useCommand(
    async (updateStatusModel: UpdateStatusModel) =>
      api.updateTaskStatus({
        taskId,
        updateStatusModel,
      }),
    {
      ...options,
      onSuccess: () => {
        options.onSuccess?.();
        partialMutate(keys.all());
      },
    }
  );
};

export { useUpdateTaskStatus };
