import { TasksApi } from "generated/tasks/apis";
import { useApi } from "providers/ApiProvider/ApiProvider";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { CreateTaskModel } from "generated/tasks/models";
import * as keys from "api/tasks/keys";
import usePartialMutate from "sdk/hooks/usePartialMutate/usePartialMutate";

const useCreateTask = (
  options: UseCommandOptions<void, ResponseError> = {}
) => {
  const api = useApi(TasksApi);
  const partialMutate = usePartialMutate();

  return useCommand(
    async (createTaskModel: CreateTaskModel) =>
      api.createTask({
        createTaskModel,
      }),
    {
      ...options,
      onSuccess: () => {
        partialMutate(keys.list());
        options.onSuccess?.();
      },
    }
  );
};

export { useCreateTask };
