import { TasksApi } from "generated/tasks/apis";
import { useApi } from "providers/ApiProvider/ApiProvider";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { AddCommentModel } from "generated/tasks/models";
import * as keys from "../keys";
import { ResponseError } from "generated";
import { useSWRConfig } from "swr";

const useAddComment = (
  taskId: number,
  options?: UseCommandOptions<void, ResponseError>
) => {
  const api = useApi(TasksApi);
  const swr = useSWRConfig();

  return useCommand(
    async (commentModel: AddCommentModel) =>
      api.addComment({
        taskId,
        addCommentModel: commentModel,
      }),
    {
      ...options,
      onSuccess: () => {
        swr.mutate(keys.detail(taskId));
        options?.onSuccess?.();
      },
    }
  );
};

export { useAddComment };
