import { TasksApi } from "generated/tasks/apis";
import { useApi } from "providers/ApiProvider/ApiProvider";
import { useQueryPaged } from "@ldms/mui-sdk/cqrs";
import * as keys from "./keys";
import { TaskListItemModel } from "generated/tasks/models";
import { PagedQuery } from "@ldms/mui-sdk/cqrs/useQueryPaged";

type UseListTasksFilters = {
  assigneeId?: string;
  from?: Date;
  to?: Date;
  customerId?: number;
  agreementId?: number;
};

const useListTasks: PagedQuery<TaskListItemModel, UseListTasksFilters> = ({
  pageSize,
  params = {},
} = {}) => {
  const api = useApi(TasksApi);
  return useQueryPaged(
    keys.list(params),
    async (_key, paging) =>
      api.listTasksRaw({
        limit: paging.pageSize,
        offset: paging.offset,
        agreementId: params.agreementId,
        customerId: params.customerId,
        assigneeId: params.assigneeId,
        from: params.from,
        to: params.to,
      }),
    { limit: pageSize }
  );
};

export { useListTasks };
