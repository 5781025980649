import { MuiSDKProvider } from "@ldms/mui-sdk/system";
import { Box, Button, CssBaseline, Typography } from "@mui/material";
import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";

const UnexpectedError = ({ resetErrorBoundary }: Partial<FallbackProps>) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center" height="100%" paddingTop={4}>
      <Box
        aria-describedby="error-description"
        aria-labelledby="error-label"
        role="alert"
        maxWidth={300}
        textAlign="center"
      >
        <Typography id="error-label" gutterBottom variant="h6">
          {t("error_boundary.unexpected_error_title")}
        </Typography>
        <Typography id="error-description">
          {t("error_boundary.unexpected_error_message")}
        </Typography>
        <Box marginTop={2}>
          <Button
            color="primary"
            onClick={resetErrorBoundary}
            variant="contained"
          >
            {t("error_boundary.unexpected_error_retry_button")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const AppLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { i18n, ready } = useTranslation();

  if (!ready) {
    return null;
  }

  return (
    <ErrorBoundary FallbackComponent={UnexpectedError}>
      <MuiSDKProvider language={i18n.language}>
        <CssBaseline />
        {children}
      </MuiSDKProvider>
    </ErrorBoundary>
  );
};

export { AppLayout };
