import { AssigneeApi } from "generated/tasks/apis";
import { useApi } from "providers/ApiProvider/ApiProvider";
import { useQuery } from "@ldms/mui-sdk/cqrs";
import * as keys from "./keys";

const useListAssignees = () => {
  const api = useApi(AssigneeApi);

  return useQuery(keys.list(), () => api.listAssignees());
};

export { useListAssignees };
