import { Container } from "@mui/material";
import { Box } from "@mui/system";
import { AppLayout } from "bootstrap/AppLayout";
import { ListApps } from "modules/start/views/ListApps";
import { TasksView } from "modules/tasks/views/TasksView";
import { Navigate, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <AppLayout>
      <Routes>
        <Route path="/" element={<Navigate to="start" replace />} />
        <Route
          path="start"
          element={
            <Box marginY={3}>
              <Container>
                <ListApps />
              </Container>
            </Box>
          }
        />
        <Route path="/tasks" element={<TasksView />} />
      </Routes>
    </AppLayout>
  );
};

export default App;
