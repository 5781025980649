import { Close } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  IconButton,
  Chip,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import {
  DescriptionList,
  AddButton,
  EditButton,
} from "@ldms/mui-sdk/components";
import { useGetTask } from "api/tasks/getTask/getTask";
import { TaskStatusEnum } from "../TasksContainer";
import {
  TaskModel,
  TaskStatusModel,
  UpdateStatusModelStatusEnum,
} from "generated/tasks/models";
import { blue, green, orange, red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { parseISO, format } from "date-fns";
import { Loader } from "@ldms/mui-sdk/templates";
import QueryError from "sdk/components/QueryError";
import { useAddComment } from "api/tasks/comments/addComment";
import { useForm } from "react-hook-form";
import useYupResolver from "sdk/hooks/useYupResolver";
import { useUpdateTaskStatus } from "api/tasks/updateTaskStatus/updateTaskStatus";
import useLocale from "sdk/hooks/useLocale";
import EditTaskDialog, {
  EditTaskFieldValues,
} from "modules/tasks/containers/EditTaskDialog/EditTaskDialog";
import { useState } from "react";
import {
  ReopenTaskDialog,
  ReopenTaskFieldValues,
} from "modules/tasks/containers/ReopenTaskDialog/ReopenTaskDialog";

type ViewTaskContainerProps = {
  open: boolean;
  onClose: () => void;
  taskId: number;
};

type CommentsDataModel = {
  comment: string;
};

const ViewTaskContainer = ({
  open,
  onClose,
  taskId,
}: ViewTaskContainerProps) => {
  const [openEditTask, setOpenEditTask] = useState(false);
  const [openReopenTask, setOpenReopenTask] = useState(false);
  const { t } = useTranslation(["tasks"]);
  const task = useGetTask(taskId);
  const locale = useLocale();

  const createComment = useAddComment(taskId, {
    onSuccess: () => form.reset(),
  });

  const taskStatusUpdate = useUpdateTaskStatus(taskId, {
    onSuccess: () =>
      createComment.execute({ comment: t("comments.comment_task_completed") }),
  });

  const resolver = useYupResolver<CommentsDataModel>((yup) =>
    yup.object().shape({
      comment: yup
        .string()
        .maxCharacters(250, "Comment")
        .isRequired(t("comments.comment_field_label")),
    })
  );

  const form = useForm<CommentsDataModel>({
    resolver: resolver,
  });

  const statusColourMap = new Map<TaskStatusModel, string>([
    [TaskStatusModel.Overdue, red[700]],
    [TaskStatusModel.Urgent, orange[500]],
    [TaskStatusModel.OnTrack, green[400]],
    [TaskStatusModel.Complete, blue[400]],
  ]);

  const handleTaskStatusUpdate = () => {
    taskStatusUpdate.execute({
      status: UpdateStatusModelStatusEnum.Complete,
    });
  };

  const onSubmit = (data: CommentsDataModel) => {
    createComment.execute(data);
  };

  const handleCloseReopenTaskDialog = () => {
    setOpenReopenTask(false);
  };

  const reopenTaskDefaultValues = (
    defaultValues: Pick<TaskModel, "dueAt">
  ): ReopenTaskFieldValues => {
    const splitDateTime = defaultValues.dueAt.split("T");
    const date = splitDateTime && new Date(splitDateTime[0]);
    const time = splitDateTime?.[1];

    return {
      dueAt: date,
      dueTime: time,
    };
  };

  const defaultValues = (defaultValues: TaskModel): EditTaskFieldValues => {
    const splitDateTime = defaultValues.dueAt.split("T");
    const date = splitDateTime && new Date(splitDateTime[0]);
    const time = splitDateTime?.[1];

    return {
      agreement: {
        customerId: defaultValues.customerId,
        id: defaultValues.agreementId,
        name: defaultValues.agreementNumber,
      },
      assigneeToId: {
        id: defaultValues.assignedToId,
        name: defaultValues.assignedTo,
      },
      customer: {
        id: defaultValues.customerId,
        name: defaultValues.customerName,
      },
      description: defaultValues.description,
      title: defaultValues.title,
      dueAt: date,
      dueTime: time,
    };
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="task-dialog"
      >
        <Loader
          ready={Boolean(task.data ?? task.error)}
          render={() => {
            if (task.error || !task.data) {
              return <QueryError onRetry={task.refetch} />;
            }
            return (
              <>
                <DialogTitle id="task-dialog-title">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h2" component="h1">
                      {task.data?.title}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={2}
                    >
                      <Chip
                        label={t(TaskStatusEnum[task.data.status])}
                        sx={{
                          backgroundColor: statusColourMap.get(
                            task.data.status
                          ),
                          color: "white",
                          lineHeight: 14,
                        }}
                        size="medium"
                      />
                      {task.data.status !== TaskStatusModel.Complete ? (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={handleTaskStatusUpdate}
                        >
                          {t("view_task.complete_button")}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => setOpenReopenTask(true)}
                        >
                          {t("view_task.reopen_button")}
                        </Button>
                      )}
                      {task.data.status !== TaskStatusModel.Complete && (
                        <EditButton
                          variant="contained"
                          size="small"
                          onClick={() => setOpenEditTask(true)}
                          labels={{
                            edit: t("common:edit_button"),
                          }}
                        />
                      )}
                      <IconButton
                        aria-label={t("common:close")}
                        onClick={onClose}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <Box mb={2}>
                    <Box my={2}>
                      <Typography
                        variant="body1"
                        component="h3"
                        mb={1}
                        id={t("view_task.description_label")}
                      >
                        <strong>{t("view_task.description_label")}</strong>
                      </Typography>
                      <Typography variant="body1">
                        {task.data.description}
                      </Typography>
                    </Box>
                    <Box>
                      <DescriptionList
                        label={t("view_task.task_details_label")}
                      >
                        <DescriptionList.Item
                          label={t("view_task.customer_label")}
                        >
                          {task.data.customerName}
                        </DescriptionList.Item>
                        <DescriptionList.Item
                          label={t("view_task.agreement_label")}
                        >
                          {task.data.agreementNumber}
                        </DescriptionList.Item>
                        <DescriptionList.Item
                          label={t("view_task.assignee_label")}
                        >
                          {task.data.assignedTo}
                        </DescriptionList.Item>
                        <DescriptionList.Item
                          label={t("view_task.deadline_label")}
                        >
                          {format(
                            parseISO(task.data.dueAt),
                            "dd/MM/yyyy - HH:mm"
                          )}
                        </DescriptionList.Item>
                      </DescriptionList>
                    </Box>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="body1">
                    <strong>
                      {t("comments.comment_counter_label")} (
                      {task.data.comments.length})
                    </strong>
                  </Typography>
                  <Box mt={2}>
                    <form action="POST" onSubmit={form.handleSubmit(onSubmit)}>
                      <TextField
                        multiline
                        label={t("comments.comment_field_label")}
                        id="comments"
                        fullWidth
                        rows={3}
                        variant="outlined"
                        margin="none"
                        error={Boolean(form.formState.errors.comment?.message)}
                        helperText={form.formState.errors.comment?.message}
                        {...form.register("comment")}
                      />
                      <Box
                        display="flex"
                        alignContent="end"
                        justifyContent="end"
                        mt={1}
                      >
                        <AddButton
                          variant="contained"
                          type="submit"
                          size="small"
                          labels={{
                            add: t("common:add"),
                          }}
                        />
                      </Box>
                    </form>
                    <List>
                      {task.data.comments.map((comment) => (
                        <ListItem key={uuidv4()} sx={{ padding: 0 }}>
                          <ListItemText
                            primary={
                              <Box
                                sx={{
                                  padding: 0.5,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                  wordWrap: "break-word",
                                }}
                              >
                                <Typography
                                  textTransform="capitalize"
                                  variant="caption"
                                >
                                  {comment.createdBy}{" "}
                                  <i>
                                    {locale.formatDateTime(
                                      new Date(comment.createdAt)
                                    )}
                                  </i>
                                </Typography>
                                <Box>
                                  <Typography variant="body1">
                                    {comment.comment}
                                  </Typography>
                                </Box>
                                <Divider />
                              </Box>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </DialogContent>
              </>
            );
          }}
        />
      </Dialog>

      {task.data && openReopenTask && (
        <ReopenTaskDialog
          defaultValues={reopenTaskDefaultValues(task.data)}
          onClose={handleCloseReopenTaskDialog}
          open={openReopenTask}
          taskId={taskId}
        />
      )}

      {task.data && openEditTask && (
        <EditTaskDialog
          open={openEditTask}
          defaultValues={defaultValues(task.data)}
          onClose={() => setOpenEditTask(false)}
          taskId={taskId}
        />
      )}
    </>
  );
};

export { ViewTaskContainer };
