import { getEnv } from "env";
import i18n, { i18n as I18n, InitOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

export const getConfig = (): InitOptions => {
  const baseUrl = getEnv().BASE_URL ? `${getEnv().BASE_URL.slice(1)}/` : "";

  return {
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    load: "languageOnly",
    react: {
      useSuspense: false, // disable experimental feature
    },
    backend: {
      loadPath: baseUrl.concat(
        `locales/{{lng}}/{{ns}}.json?version=${__ASSET_VERSION__}`
      ),
    },
  };
};

export default function makeI18n(overrides: InitOptions = {}): I18n {
  i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({ ...getConfig(), ...overrides })
    .catch(console.error);

  return i18n;
}
