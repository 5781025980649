import {
  AppProvider,
  EngageApp,
  EngageConfigContext,
  SessionProvider,
} from "@ldms/mui-sdk/bootstrap";
import { MuiSDKProvider } from "@ldms/mui-sdk/system";
import App from "App";
import NavigationList from "common/components/NavigationList";
import EnvironmentProvider, {
  useEnvironment,
} from "providers/EnvironmentProvider/EnvironmentProvider";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import makeI18n from "support/make-i18n";

makeI18n({
  defaultNS: "common",
  ns: ["common"],
});

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
const apiConfiguration = { basePath: "/api/task-management" };

const AppWrapper: React.FC = () => {
  const environment = useEnvironment();

  return (
    <EngageApp
      apiConfiguration={apiConfiguration}
      baseUrl={import.meta.env.BASE_URL}
      isDemoEnvironment={!environment.isProduction}
      topbar={<NavigationList />}
    >
      <App />
    </EngageApp>
  );
};

export const config = {
  appRoutes: {
    admin: "/admin",
    auth: "/auth",
    loginPath: "/oauth2/authorization/engage",
    users: "/users",
    documents: "/documents",
    onboarding: "/onboarding",
    finance: "/finance",
    reporting: "/reporting",
    servicing: "/servicing",
    thirdParties: "/third-parties",
    tasks: "/tasks",
  },
} as const;

root.render(
  <React.StrictMode>
    <EngageConfigContext.Provider value={config}>
      <AppProvider authorizationUri={config.appRoutes.loginPath}>
        <SessionProvider>
          <EnvironmentProvider application="tasks">
            <BrowserRouter>
              <MuiSDKProvider>
                <AppWrapper />
              </MuiSDKProvider>
            </BrowserRouter>
          </EnvironmentProvider>
        </SessionProvider>
      </AppProvider>
    </EngageConfigContext.Provider>
  </React.StrictMode>
);
