import { TasksApi } from "generated/tasks/apis";
import { useApi } from "providers/ApiProvider/ApiProvider";
import { useCommand, UseCommandOptions } from "@ldms/mui-sdk/cqrs";
import { ResponseError } from "@ldms/mui-sdk/bootstrap";
import { EditTaskModel } from "generated/tasks/models";
import * as keys from "api/tasks/keys";
import usePartialMutate from "sdk/hooks/usePartialMutate";

const useEditTask = (
  taskId: number,
  options: UseCommandOptions<void, ResponseError> = {}
) => {
  const api = useApi(TasksApi);
  const partialMutate = usePartialMutate();

  return useCommand(
    async (editTaskModel: EditTaskModel) =>
      api.editTask({
        taskId,
        editTaskModel,
      }),
    {
      ...options,
      onSuccess: () => {
        options.onSuccess?.();
        partialMutate(keys.all());
      },
    }
  );
};

export { useEditTask };
