/* tslint:disable */
/* eslint-disable */
/**
 * Engage Tasks
 * API for managing tasks
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddCommentModel,
  CreateTaskModel,
  CustomersAndAgreementsModel,
  EditTaskModel,
  ErrorWithParametersModel,
  TaskListItemModel,
  TaskModel,
  UpdateStatusModel,
} from '../models';
import {
    AddCommentModelFromJSON,
    AddCommentModelToJSON,
    CreateTaskModelFromJSON,
    CreateTaskModelToJSON,
    CustomersAndAgreementsModelFromJSON,
    CustomersAndAgreementsModelToJSON,
    EditTaskModelFromJSON,
    EditTaskModelToJSON,
    ErrorWithParametersModelFromJSON,
    ErrorWithParametersModelToJSON,
    TaskListItemModelFromJSON,
    TaskListItemModelToJSON,
    TaskModelFromJSON,
    TaskModelToJSON,
    UpdateStatusModelFromJSON,
    UpdateStatusModelToJSON,
} from '../models';

export interface AddCommentRequest {
    taskId: number;
    addCommentModel?: AddCommentModel;
}

export interface CreateTaskRequest {
    createTaskModel?: CreateTaskModel;
}

export interface EditTaskRequest {
    taskId: number;
    editTaskModel?: EditTaskModel;
}

export interface FindCustomersAndAgreementsRequest {
    query?: string;
    limit?: number;
    customerId?: number;
}

export interface GetTaskRequest {
    taskId: number;
}

export interface ListTasksRequest {
    offset?: number;
    limit?: number;
    assigneeId?: string;
    agreementId?: number;
    customerId?: number;
    from?: Date;
    to?: Date;
}

export interface UpdateTaskStatusRequest {
    taskId: number;
    updateStatusModel?: UpdateStatusModel;
}

/**
 * 
 */
export class TasksApi extends runtime.BaseAPI {

    /**
     * Add a comment for task
     */
    async addCommentRaw(requestParameters: AddCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling addComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tasks/{taskId}/comments`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddCommentModelToJSON(requestParameters.addCommentModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a comment for task
     */
    async addComment(requestParameters: AddCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Create a Task
     */
    async createTaskRaw(requestParameters: CreateTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tasks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTaskModelToJSON(requestParameters.createTaskModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a Task
     */
    async createTask(requestParameters: CreateTaskRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createTaskRaw(requestParameters, initOverrides);
    }

    /**
     * Edit an existing task
     */
    async editTaskRaw(requestParameters: EditTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling editTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tasks/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditTaskModelToJSON(requestParameters.editTaskModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit an existing task
     */
    async editTask(requestParameters: EditTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editTaskRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve a list of agreements and customer that match a query
     */
    async findCustomersAndAgreementsRaw(requestParameters: FindCustomersAndAgreementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomersAndAgreementsModel>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomersAndAgreementsModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a list of agreements and customer that match a query
     */
    async findCustomersAndAgreements(requestParameters: FindCustomersAndAgreementsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomersAndAgreementsModel> {
        const response = await this.findCustomersAndAgreementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the details of a task
     */
    async getTaskRaw(requestParameters: GetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaskModel>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling getTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskModelFromJSON(jsonValue));
    }

    /**
     * Retrieve the details of a task
     */
    async getTask(requestParameters: GetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaskModel> {
        const response = await this.getTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a list of tasks
     */
    async listTasksRaw(requestParameters: ListTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TaskListItemModel>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.assigneeId !== undefined) {
            queryParameters['assigneeId'] = requestParameters.assigneeId;
        }

        if (requestParameters.agreementId !== undefined) {
            queryParameters['agreementId'] = requestParameters.agreementId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskListItemModelFromJSON));
    }

    /**
     * Retrieve a list of tasks
     */
    async listTasks(requestParameters: ListTasksRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TaskListItemModel>> {
        const response = await this.listTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Amend a tasks status
     */
    async updateTaskStatusRaw(requestParameters: UpdateTaskStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling updateTaskStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/tasks/{taskId}`.replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStatusModelToJSON(requestParameters.updateStatusModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Amend a tasks status
     */
    async updateTaskStatus(requestParameters: UpdateTaskStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTaskStatusRaw(requestParameters, initOverrides);
    }

}
