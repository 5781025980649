import { useEngageConfig, useSession } from "@ldms/mui-sdk/bootstrap";
import { AdminPanelSettings } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import useId from "@mui/utils/useId";
import React from "react";
import { useTranslation } from "react-i18next";

const permissionContains = (permissions: Set<string>, subString: string) => {
  return Array.from(permissions).some((permission) =>
    permission.startsWith(subString)
  );
};

const useAppList = () => {
  const engageConfig = useEngageConfig();
  const { t } = useTranslation();
  const session = useSession();

  return [
    {
      url: engageConfig.appRoutes.servicing,
      name: t("app_list.servicing_name"),
      description: t("app_list.servicing_description"),
      permissions: permissionContains(session.data.permissions, "servicing:"),
    },
    {
      url: engageConfig.appRoutes.documents,
      name: t("app_list.documents_name"),
      description: t("app_list.documents_description"),
      permissions: permissionContains(session.data.permissions, "documents:"),
    },
    {
      url: engageConfig.appRoutes.onboarding,
      name: t("app_list.onboarding_name"),
      description: t("app_list.onboarding_description"),
      permissions: permissionContains(session.data.permissions, "onboarding:"),
    },
    {
      url: engageConfig.appRoutes.finance,
      name: t("app_list.finance_name"),
      description: t("app_list.finance_description"),
      permissions: permissionContains(session.data.permissions, "finance:"),
    },
    {
      url: engageConfig.appRoutes.reporting,
      name: t("app_list.reporting_name"),
      description: t("app_list.reporting_description"),
      permissions: permissionContains(session.data.permissions, "reporting:"),
    },
  ] as const;
};

const AppCard: React.FC<{
  name: string;
  description: string;
  href: string;
  icon?: React.ReactElement;
}> = ({ name, description, href, icon }) => {
  const labelId = `app-card-${useId()}`;

  return (
    <Card>
      <CardActionArea component="a" href={href} aria-labelledby={labelId}>
        <CardContent>
          <Box display="flex" gap={1.5}>
            {icon && (
              <Box>
                <Avatar>{icon}</Avatar>
              </Box>
            )}
            <Box>
              <Typography gutterBottom variant="h5" id={labelId}>
                {name}
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {description}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const ListApps = () => {
  const { t } = useTranslation();
  const session = useSession();
  const appList = useAppList();

  return (
    <>
      <Box marginBottom={3} marginTop={4}>
        <Typography component="h1" variant="h3">
          {t("choose_an_app_heading")}
        </Typography>
      </Box>

      <Box role="list">
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={3}>
          {appList
            .filter((app) => app.permissions)
            .map((app) => (
              <Box key={app.name} role="listitem">
                <AppCard
                  name={app.name}
                  description={app.description}
                  href={app.url}
                />
              </Box>
            ))}
        </Box>

        {permissionContains(session.data.permissions, "admin:") && (
          <>
            <Box marginY={3}>
              <Divider light />
            </Box>

            <Box
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
              gap={3}
              role="listitem"
            >
              <AppCard
                icon={<AdminPanelSettings />}
                name={t("app_list.admin_name")}
                description={t("app_list.admin_description")}
                href="/admin"
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export { ListApps };
