import { ApiContext } from "@ldms/mui-sdk/bootstrap";
import { BaseAPI, Configuration } from "generated";
import { useContext } from "react";

export const useApi = <T extends BaseAPI>(Api: {
  new (config: Configuration): T;
}): T => {
  const apiContext = useContext(ApiContext);

  return new Api(new Configuration(apiContext));
};
